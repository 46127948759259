import React, { useEffect, useRef, useState } from 'react';

const FlappyBird = ({ onGameOver, isGameStarted }) => {
  const canvasRef = useRef(null);
  const scoreRef = useRef(null);
  const modalRef = useRef(null); // Ref for modal

  const [isGameOver, setIsGameOver] = useState(false);

  const bird = new Image();
  const bg = new Image();
  const fg = new Image();
  const pipeNorth = new Image();
  const pipeSouth = new Image();
  const gameOverImage = new Image();

  bird.src = 'https://gaccdiscordimages.s3.amazonaws.com/vitalik.png';
  bg.src = 'https://gaccdiscordimages.s3.amazonaws.com/fb_bg.png';
  fg.src = 'https://gaccdiscordimages.s3.amazonaws.com/base.png';
  pipeNorth.src = 'https://gaccdiscordimages.s3.amazonaws.com/pipe-red.png';
  pipeSouth.src = 'https://gaccdiscordimages.s3.amazonaws.com/pipe-green.png';
  gameOverImage.src = 'https://gaccdiscordimages.s3.amazonaws.com/fb_start_bg.png';

  const gap = 150;
  let constant;
  let bX = 10;
  let bY = 150;
  const gravity = 1.5;
  let score = 0;

  const fly = new Audio('https://gaccdiscordimages.s3.amazonaws.com/wing.mp3');
  const scor = new Audio('https://gaccdiscordimages.s3.amazonaws.com/point.mp3');

  const pipe = [];
  pipe[0] = {
    x: canvasRef.current?.width || 0,
    y: 10, // Adjust this value to make the gap larger
  };

  let animationFrameId;

  const moveUp = () => {
    bY -= 25;
    fly.play().catch(error => console.log('Audio play error:', error));
  };

  const draw = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    if (isGameOver || !isGameStarted) return;

    ctx.drawImage(bg, 0, 0);

    for (const p of pipe) {
      constant = pipeNorth.height + gap;
      ctx.drawImage(pipeNorth, p.x, p.y);
      ctx.drawImage(pipeSouth, p.x, p.y + constant);

      p.x--;

      if (p.x === 125) {
        pipe.push({
          x: canvas.width,
          y: Math.floor(Math.random() * (canvas.height / 2 - pipeNorth.height)) - (pipeNorth.height / 2),
        });
      }

      // Check for collision only if the bird is within the x-axis range of the pipes
      if (bX + bird.width >= p.x && bX <= p.x + pipeNorth.width) {
        if (
          (bY <= p.y + pipeNorth.height - 10 ||  // More lenient collision detection
            bY + bird.height >= p.y + constant + 10) ||  // More lenient collision detection
          bY + bird.height >= canvas.height - fg.height
        ) {
          setIsGameOver(true);
          cancelAnimationFrame(animationFrameId);
          ctx.drawImage(gameOverImage, 0, 0, canvas.width, canvas.height);
          showModal();
          onGameOver();
          return;
        }
      }

      if (p.x === 5) {
        score++;
        scor.play().catch(error => console.log('Audio play error:', error));
      }
    }

    ctx.drawImage(fg, 0, canvas.height - fg.height);
    ctx.drawImage(bird, bX, bY);

    bY += gravity;

    ctx.fillStyle = '#000';
    ctx.font = '20px Verdana';
    ctx.fillText('Score: ' + score, 10, canvas.height - 20);

    animationFrameId = requestAnimationFrame(draw);
  };

  useEffect(() => {
    if (!isGameStarted) return;
  
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    let touchInterval; // To store interval ID for continuous movement
  
    const handleTouchStart = () => {
      moveUp();
      // Start continuous movement when touch starts
      touchInterval = setInterval(moveUp, 100); // Adjust the interval as needed
    };
  
    const handleTouchEnd = () => {
      // Stop continuous movement when touch ends
      clearInterval(touchInterval);
    };
  
    document.addEventListener('keydown', moveUp);
    canvas.addEventListener('touchstart', handleTouchStart);
    canvas.addEventListener('touchend', handleTouchEnd);
  
    draw();
  
    return () => {
      cancelAnimationFrame(animationFrameId);
      document.removeEventListener('keydown', moveUp);
      canvas.removeEventListener('touchstart', handleTouchStart);
      canvas.removeEventListener('touchend', handleTouchEnd);
      // Clear interval when component unmounts
      clearInterval(touchInterval);
    };
  }, [isGameStarted, isGameOver]);

  useEffect(() => {
    if (isGameStarted && !isGameOver) {
      bX = 10;
      bY = 150;
      score = 0;

      pipe.length = 0;
      pipe[0] = {
        x: canvasRef.current.width,
        y: Math.floor(Math.random() * (canvasRef.current.height / 2)) - (pipeNorth.height + gap / 2),
      };
    }
  }, [isGameStarted, isGameOver]);

  const showModal = () => {
    const modal = modalRef.current; // Access modal using the ref
    scoreRef.current.textContent = score;
    modal.style.display = 'block';
  };

  const closeModal = () => {
    const modal = modalRef.current; // Access modal using the ref
    modal.style.display = 'none';
  };

  return (
    <div className="game-container">
      <canvas id="canvas" ref={canvasRef} width="320" height="480"></canvas>
      <div id="modal" className="modal" ref={modalRef}> {/* Added ref */}
        <div className="modal-content">
          <span className="close-btn" onClick={() => { closeModal(); setIsGameOver(false); }}>&times;</span>
          <h3>Gah! Poor Vitalik!</h3>
          <p>Your score: <span id="score" ref={scoreRef}></span></p>
          <div className='game-over-footer'>
            <p>by BTC on ETH</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FlappyBird;
