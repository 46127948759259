import React, { useEffect, useState } from 'react';
import Web3 from 'web3';

const Dashboard = () => {
  const initialDate = new Date('2015-11-03T18:30:13Z');
  const lpDate = new Date('2024-05-27T18:30:13Z');
  const initialBlock = 484730;  // Use regular number for the initial block number

  const [timeSince, setTimeSince] = useState('');
  const [timeSinceLp, setTimeSinceLp] = useState('');
  const [currentBlock, setCurrentBlock] = useState(0);  // Default to a regular number
  const [error, setError] = useState(null);  // State for error messages

  useEffect(() => {
  const intervalId = setInterval(() => {
    const now = new Date();

    // Calculate the differences in milliseconds
    let diff = now - initialDate;

    // Convert milliseconds to years, months, days, hours, minutes, seconds
    let years = Math.floor(diff / (1000 * 60 * 60 * 24 * 365.25));
    let months = Math.floor((diff % (1000 * 60 * 60 * 24 * 365.25)) / (1000 * 60 * 60 * 24 * 30.4375));
    let days = Math.floor((diff % (1000 * 60 * 60 * 24 * 30.4375)) / (1000 * 60 * 60 * 24));
    let hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    let minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
    let seconds = Math.floor((diff % (1000 * 60)) / 1000);

    // Format numbers with comma separators
    const formattedYears = years.toLocaleString('en-US');
    const formattedMonths = months.toLocaleString('en-US');
    const formattedDays = days.toLocaleString('en-US');
    const formattedHours = hours.toLocaleString('en-US');
    const formattedMinutes = minutes.toLocaleString('en-US');
    const formattedSeconds = seconds.toLocaleString('en-US');

    setTimeSince(`${formattedYears}y ${formattedMonths}m ${formattedDays}d ${formattedHours}h ${formattedMinutes}m ${formattedSeconds}s`);
  }, 1000);
  return () => clearInterval(intervalId);
}, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const now = new Date();
  
      // Calculate the differences in milliseconds
      let diff = now - lpDate;
  
      // Convert milliseconds to years, months, days, hours, minutes, seconds
      // let years = Math.floor(diff / (1000 * 60 * 60 * 24 * 365.25));
      let months = Math.floor((diff % (1000 * 60 * 60 * 24 * 365.25)) / (1000 * 60 * 60 * 24 * 30.4375));
      let days = Math.floor((diff % (1000 * 60 * 60 * 24 * 30.4375)) / (1000 * 60 * 60 * 24));
      let hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      let minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
      let seconds = Math.floor((diff % (1000 * 60)) / 1000);
  
      // Format numbers with comma separators
      // const formattedYears = years.toLocaleString('en-US');
      const formattedMonths = months.toLocaleString('en-US');
      const formattedDays = days.toLocaleString('en-US');
      const formattedHours = hours.toLocaleString('en-US');
      const formattedMinutes = minutes.toLocaleString('en-US');
      const formattedSeconds = seconds.toLocaleString('en-US');
  
      setTimeSinceLp(`${formattedMonths}m ${formattedDays}d ${formattedHours}h ${formattedMinutes}m ${formattedSeconds}s`);
    }, 1000);

  return () => clearInterval(intervalId);
}, []);

  useEffect(() => {
    const web3 = new Web3('https://rarible.com/nodes/ethereum-node');
    
    const fetchBlockNumber = async () => {
      try {
        const latestBlock = await web3.eth.getBlockNumber();
        const latestBlockBigInt = Number(latestBlock);
        const blockDiff = latestBlockBigInt - initialBlock; // Calculate block difference as regular number
        setCurrentBlock(blockDiff.toLocaleString());
      } catch (err) {
        setError(`Failed to fetch block number: ${err.message}`);
        console.error(err);
      }
    };

    fetchBlockNumber();
    const intervalId = setInterval(fetchBlockNumber, 15000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className='Dashboard'>
      <h2>Historical Clock</h2>
      <div style={{ margin: '20px 0' }}>
        <h3>Time Since Deployment</h3>
        <p>{timeSince}</p>
      </div>
      <div>
        <h3>Time Since ETH LP</h3>
        <p>{timeSinceLp}</p>
      </div>
      <div>
        <h3>Ethereum Block Confirmations</h3>
        <p>{currentBlock}</p>
      </div>
      {error && (
        <div style={{ color: 'red' }}>
          <h3>Error</h3>
          <p>{error}</p>
        </div>
      )}
    </div>
  );
};

export default Dashboard;
