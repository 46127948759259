import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Navbar from './components/Navbar';
import Leaderboard from './components/Leaderboard';
import Home from './components/Home';
import Arcade from './components/Arcade';
import Meme from './components/Meme';
import HistoryPage from './components/History';

function App() {
  return (
    <Router>
      <div className="retro-bg">
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/leaderboard" element={<Leaderboard />} />
          <Route path="/arcade" element={<Arcade />} />
          <Route path="/meme" element={<Meme />} />
          <Route path="/history" element={<HistoryPage />} />
        </Routes>
        
      </div>
    </Router>
  );
}

export default App;
