import React, { useState } from 'react';
import FlappyBird from './FlappyBird';
import { Divider, Button } from 'retro-react';

// Add event listener to disable scrolling with arrow keys
document.addEventListener('keydown', function(e) {
    // Disable scrolling with arrow keys
    if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
      e.preventDefault();
    }
  });

const Arcade = () => {


  const [isGameStarted, setIsGameStarted] = useState(false);

  const handleStartGame = () => {
    setIsGameStarted(true);
  };

  const handleGameOver = () => {
    setIsGameStarted(false);
  };

  return (
    <div style={{ userSelect: 'none' }}>
      <h1>Meme Arcade</h1>
      <div className="How-to-buy">
      </div>
      <Divider
        color="grayscale"
        orientation="horizontal"
    />
      <h3>Flappy Vitalik</h3>
      {!isGameStarted && (
        <div className="start-screen">
            <>
                <Button
                    color="greyscale-dark"
                    onClick={handleStartGame}
                    size="medium"
                    variant="solid"
                >
                    Play Game
                </Button>
                </>
          {/* <button onClick={handleStartGame}>Start Game</button> */}
        </div>
      )}
      <FlappyBird isGameStarted={isGameStarted} onGameOver={handleGameOver} />
      
      <div className="How-to-buy">
      </div>
      <Divider
        color="grayscale"
        orientation="horizontal"
    />
      
    </div>
  );
};

export default Arcade;
