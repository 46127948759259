import React, { useState, useRef } from 'react';
import { Divider } from 'retro-react';

const DF1MemeEnhancer = () => {
  const [imageData, setImageData] = useState(null);
  const canvasRef = useRef(null);

  const applyNoiseEffect = (e) => {
    const input = e.target;
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    if (input.files && input.files[0]) {
      const reader = new FileReader();

      reader.onload = function (e) {
        const img = new Image();
        img.onload = function () {
          canvas.width = img.width;
          canvas.height = img.height;
          ctx.drawImage(img, 0, 0, img.width, img.height);

          let imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);

          // Apply Noise Hurl Effect
          imageData = applyHurlNoiseEffect(imageData);

          // Apply Slur Noise Effect without blur
          imageData = applySlurNoiseEffect(imageData);

          // Apply Random Colored Glitch Effect
          imageData = applyGlitchEffect(imageData);

          ctx.putImageData(imageData, 0, 0);
        };
        img.src = e.target.result;
      };

      reader.readAsDataURL(input.files[0]);
    }
  };

  const applyHurlNoiseEffect = (imageData) => {
    const data = imageData.data;
    for (let i = 0; i < data.length; i += 4) {
      data[i] += 25 - Math.random() * 50; // Red
      data[i + 1] += 25 - Math.random() * 50; // Green
      data[i + 2] += 25 - Math.random() * 50; // Blue
    }
    return imageData;
  };

  const applySlurNoiseEffect = (imageData) => {
    const data = imageData.data;
    const width = imageData.width;
    const height = imageData.height;
    const newData = new Uint8ClampedArray(data.length);
    newData.set(data);

    for (let y = 0; y < height; y++) {
      for (let x = 0; x < width; x++) {
        const i = (y * width + x) * 4;
        if (Math.random() > 0.5) {
          const shiftX = x + (Math.random() > 0.5 ? 1 : -1);
          const shiftY = y + (Math.random() > 0.5 ? 1 : -1);
          if (shiftX >= 0 && shiftX < width && shiftY >= 0 && shiftY < height) {
            const newI = (shiftY * width + shiftX) * 4;
            newData[i] = data[newI];
            newData[i + 1] = data[newI + 1];
            newData[i + 2] = data[newI + 2];
            newData[i + 3] = data[newI + 3];
          }
        }
      }
    }
    return new ImageData(newData, width, height);
  };

  const applyGlitchEffect = (imageData) => {
    const data = imageData.data;
    const width = imageData.width;
    const height = imageData.height;

    for (let i = 0; i < data.length; i += 4) {
      if (Math.random() < 0.01) { // Adjust for more or fewer glitches
        data[i] = Math.random() * 255; // Red
        data[i + 1] = Math.random() * 255; // Green
        data[i + 2] = Math.random() * 255; // Blue
      }
    }
    return imageData;
  };

  return (
    <div>
      <h1>DF1 Meme Enhancer</h1>
      <div className='meme-p'>
        <p>Add the iconic DF1 effect to any image. Inspired by the awesome artist Darkfarms and his meme coin $BOME. Please use and share your art!</p>
      </div>
      <div className="How-to-buy">
      </div>
      <Divider
        color="grayscale"
        orientation="horizontal"
    />
      <div className="container">
        <div className="content">
          <div className="input-area">
            <input type="file" id="imageInput" onChange={applyNoiseEffect}/>
            <canvas id="imageCanvas" ref={canvasRef}></canvas>
          </div>
        </div>
      </div>
      <div className="How-to-buy">
      </div>
      <Divider
        color="grayscale"
        orientation="horizontal"
    />
      
    </div>
  );
};

export default DF1MemeEnhancer;
