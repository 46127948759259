import React, { useState, useEffect } from 'react';
import '../App.css'; // Import your CSS file here

import Header from './Header';
import Table from './Table';


function Leaderboard() {

  const [ethHoldersCount, setEthHoldersCount] = useState(0);
  const [baseHoldersCount, setBaseHoldersCount] = useState(0);

  useEffect(() => {
    // Fetch the number of holders from your API
    let contractAddress = '0xC0BC84e95864BdfDCd1CCFB8A3AA522E79Ca1410';
    let apiUrl = `https://api.chainbase.online/v1/token/holders?chain_id=1&contract_address=${contractAddress}&page=1&limit=1`;
    if (ethHoldersCount === 0) {
      fetch(apiUrl, {
        headers: {
          'Accept': 'application/json',
          'x-api-key': '2gXi1f3zVcKu3Pwqf2dCFJyTdCS'
        }
      })
      .then(response => response.json())
      .then(data => setEthHoldersCount(data.count))
      .catch(error => console.error('Error fetching holders count:', error));
    }
    

    // Fetch Base
    if (baseHoldersCount === 0) {
      contractAddress = '0xceA0Be764485f3E22Aa843dFfF3D189F5af6a361';
      apiUrl = `https://api.chainbase.online/v1/token/holders?chain_id=8453&contract_address=${contractAddress}&page=1&limit=1`;
      
      fetch(apiUrl, {
        headers: {
          'Accept': 'application/json',
          'x-api-key': '2icercyznFn81Zmc2lkI0RoWzP0'
        }
      })
      .then(response => response.json())
      .then(data => setBaseHoldersCount(data.count))
      .catch(error => console.error('Error fetching holders count:', error));
    }
    
  }, []);

  return (
    <div className="App">
      <Header title="$btc Leaderboard" />
      <h2>Holders: {ethHoldersCount + baseHoldersCount}</h2>
      <h4>Ethereum Holders:{ethHoldersCount}</h4>
      <h4>Base Holders: {baseHoldersCount}</h4>
      <Table />
    </div>
  );
}

export default Leaderboard;