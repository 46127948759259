import React from 'react';

function TableRow({ holder, rank }) {
  const images = [];
  if (holder.eth) images.push('ethereum.png');
  if (holder.base) images.push('base.png');

  return (
    <tr>
      <td>{rank}</td>
      <td><div className='scrollable'>{holder.wallet_address}</div></td>
      <td><div className='scrollable'>{holder.message}</div></td>
      <td><div className='scrollable'>{Math.floor(holder.amount).toLocaleString()}</div></td>
      <td>
        <div className="image-container">
          {images.map((imageUrl, index) => (
            <img
              key={index}
              src={imageUrl}
              alt={`${index + 1}`}
              className="small-image"
            />
          ))}
        </div>
      </td>
    </tr>
  );
}

export default TableRow;
