// Navbar.js
import React from 'react';
import { Link } from 'react-router-dom';

function Navbar() {
  return (
    <nav className="Navbar retro-bg">
      <div className="Nav-left">
        <Link to="/"><img src="btc_logo_circular.png" alt="Logo" /><p style={{color: "#F78402"}}> bitcoin</p></Link>
      </div>
      <div className="Nav-right">
        <ul>
          <li><Link to="/leaderboard">LEADERBOARD</Link></li>
          <li><Link to="/meme">MEME</Link></li>
          <li><Link to="/arcade">ARCADE</Link></li>
        </ul>
      </div>
    </nav>
  );
}

export default Navbar;
