import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const HistoryPage = () => {
  const [data, setData] = useState([]);
  const [sidebarContent, setSidebarContent] = useState('');
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [formData, setFormData] = useState({
    date: "",
    block: "Deployment block number if you know it",
    projectName: "",
    website: "",
    additionalInfo: "Short project description",
    image: "link to small suitable image",
  });

  const navigate = useNavigate();

  useEffect(() => {
    fetch('data.json')
  .then(response => {
    if (!response.ok) {
      throw new Error('Failed to fetch data');
    }
    return response.text();  // Read as text first
  })
  .then(text => {
    console.log('Fetched text:', text);  // Log raw text
    return JSON.parse(text);  // Then parse the JSON
  })
  .then(json => {
    console.log(json)
    setData(json);
    generateGrid();
  })
  .catch(error => {
    console.error('Error fetching data:', error);
  });
  }, []);

  const generateGrid = () => {
    const gridItems = [];
    const groups = [155, 366, 365, 365, 365, 366, 365, 365, 365, 366];
    let startDate = new Date('2015-07-30');
    let index = 0;
    const currentDate = new Date().toISOString().split('T')[0];
  
    groups.forEach((groupSize, groupIndex) => {
      const year = 2015 + groupIndex;
      year.toString().split('').forEach((yearDigit, digitIndex) => {
        gridItems.push(
          <div
            key={`year-${groupIndex}-${digitIndex}`}
            className="grid-item year-cell"
          >
            {yearDigit}
          </div>
        );
      });
  
      for (let i = 0; i < groupSize; i++, index++) {
        let cellDate = new Date(startDate.getTime() + index * 24 * 60 * 60 * 1000);
        let dateId = cellDate.toISOString().split('T')[0];
        let cellContent = '';
  
        const entry = data.find(item => item.date === dateId);
        if (entry && entry.Image) {
          cellContent = <img src={entry.Image} alt={entry['Project Name']} style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }} />;
        }
  
        const backgroundColor = dateId === currentDate ? 'rgb(50, 150, 255)' : getShade(groupIndex);
  
        gridItems.push(
          <a
            href
            key={dateId}
            className="grid-item"
            style={{ backgroundColor }}
            onClick={() => openSidebar(dateId)}
            title={dateId}
          >
            {cellContent}
          </a>
        );
      }
    });
  
    return gridItems;
  };
  

  const getShade = (groupIndex) => {
    const shades = [
      'rgb(250, 250, 250)',   // 2015
      'rgb(245, 245, 245)',   // 2016
      'rgb(235, 235, 235)',   // 2017
      'rgb(225, 225, 225)',   // 2018
      'rgb(215, 215, 215)',   // 2019
      'rgb(205, 205, 205)',   // 2020
      'rgb(195, 195, 195)',   // 2021
      'rgb(185, 185, 185)',   // 2022
      'rgb(175, 175, 175)',   // 2023
      'rgb(165, 165, 165)'    // 2024
  ];
    return shades[groupIndex % shades.length];
  };

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const generateJSON = () => {
    const jsonOutput = JSON.stringify(formData, null, 4);
    document.getElementById("jsonResult").textContent = jsonOutput;
    document.getElementById("jsonOutput").style.display = "block";
  };

  const openForm = (project = null, date = null) => {
    if (project) {
      setFormData({
        date: project.date,
        block: project.Block,
        projectName: project["Project Name"],
        website: project.Website,
        additionalInfo: project["Additional Info"],
        image: "",
      });
    } else {
      setFormData({
        date: date || "",
        block: "Deployment block number if you know it",
        projectName: "",
        website: "",
        additionalInfo: "Short project description",
        image: "link to small suitable image",
      });
    }
  };

  const leaderboard = [
    { name: "spiderwars", points: 100 },
    { name: "Lorkey", points: 100 },
    { name: "thiscouldbeyou", points: 10 },
  ];

  const openSidebar = (dateId) => {
    const entries = data.filter(item => item.date === dateId);
    if (entries.length > 0) {
      const content = entries.map(entry => (
        <div className="project" key={entry['Project Name']}>
          <div style={{ textAlign: "center" }}>
                <img src={entry.Image} alt="Project" style={{ width: "100%", height: "auto" }} />
              </div>
              <h3>{entry["Project Name"]}</h3>
          <p><strong>Date:</strong> {dateId}</p>
          <p><strong>Block:</strong> {entry.Block}</p>
          {entry.Website && <p><strong>Website:</strong> <a href={entry.Website} target="_blank" rel="noopener noreferrer">{entry.Website}</a></p>}
          <p><strong>Additional Info:</strong> {entry['Additional Info']}</p>
          <button onClick={() => openForm(entry, content.date)}>Propose changes</button>
        </div>
        
      ));
      
      setSidebarContent(content);
    } else {
      const exampleEntry = {
        "date": dateId,
        "Block": "484730",
        "Project Name": "bitcoin on Ethereum",
        "Website": "https://www.btconeth.com/",
        "Additional Info": "A meme coin almost as old as Ethereum itself",
      };
      setSidebarContent(
        <>
          <h3>No projects found for this date.</h3>
          <p><strong>Date:</strong> {dateId}</p>
            <button onClick={() => openForm(null, dateId)}>Contribute New Project</button>
        </>
      );
    }
    setSidebarOpen(true);
  };

  const closeSidebar = () => setSidebarOpen(false);

  return (
    <div className='history-body'>
      <div class="textbox">
        <p>This is a visual representation of the development and milestones of the Ethereum blockchain from its inception in 2015 to the present day in 2024. Each cell represents one day.</p>
        <p>Overview made by 'bitcoin on Ethereum' a bitcoin meme coin from November 3rd 2015</p>
    </div>

      <div className="grid-wrapper">
        <div className="grid-container" id="gridContainer">
          {generateGrid()}
        </div>
      </div>

      <div id="sidebar" className={`sidebar ${sidebarOpen ? 'open' : ''}`}>
      <button className="close-btn" onClick={closeSidebar}>
        Close
      </button>
        <div className="sidebar-content" id="sidebar-content">
          {sidebarContent}
          <div id="contributeForm" className="contribute-form" style={{display: "none"}}>
        <form>
          <label htmlFor="date">Date:</label>
          <input type="text" name="date" value={formData.date} onChange={handleInputChange} readOnly />
          <label htmlFor="block">Block:</label>
          <input type="text" name="block" value={formData.block} onChange={handleInputChange} />
          <label htmlFor="projectName">Project Name:</label>
          <input type="text" name="projectName" value={formData.projectName} onChange={handleInputChange} />
          <label htmlFor="website">Website:</label>
          <input type="text" name="website" value={formData.website} onChange={handleInputChange} />
          <label htmlFor="additionalInfo">Additional Info:</label>
          <input type="text" name="additionalInfo" value={formData.additionalInfo} onChange={handleInputChange} />
          <label htmlFor="image">Image:</label>
          <input type="text" name="image" value={formData.image} onChange={handleInputChange} />
          <button type="button" onClick={generateJSON}>Submit</button>
        </form>
        <div id="jsonOutput" className="json-output">
          <pre id="jsonResult"></pre>
        </div>
      </div>
          
        </div>
      </div>


      <div class="leaderboard">
        <h2>Contributor Leaderboard</h2><p>Change 1p, Addition 10p</p>
        <table>
      <thead>
        <tr>
          <th>Name</th>
          <th>Points</th>
        </tr>
      </thead>
      <tbody>
        {leaderboard.map((entry, index) => (
          <tr key={index}>
            <td>{entry.name}</td>
            <td>{entry.points}</td>
          </tr>
        ))}
      </tbody>
    </table>
    </div>

    <footer>
        <p><a href="https://x.com/ethspiderwars">@ethspiderwars</a> -- <a href="https://discord.gg/bp57JyBHkt">Discord</a></p>
    </footer>
    </div>
  );
};

export default HistoryPage;
